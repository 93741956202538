import { computed, defineComponent, h, onMounted, ref } from 'vue'
import { appendHeader } from 'h3'
import { useImage } from '../composables'
import { normalizeSize } from '../utils'
import { baseImageProps, useBaseImage } from './_base'
import { useHead } from '#imports'

const imageProps = {
  ...baseImageProps,
  placeholder: { type: [Boolean, String, Number, Array], default: undefined },
}

export default defineComponent({
  props: imageProps,

  setup: (props, context) => {
    const $img = useImage()
    const _base = useBaseImage(props)

    const placeholderLoaded = ref(false)

    type ImageAttrs = typeof _base.imageAttrs.value & {
      sizes?: string
      srcset?: string
    }

    const imgSizes = computed(() => $img.getSizes(props.src!, {
      ..._base.imageOptions.value,
      sizes: props.sizes,
      edits: {
        ..._base.imageEdits.value,
        resize: {
          ..._base.imageEdits.value.resize,
          width: normalizeSize(props.width),
          height: normalizeSize(props.height),
        },
      },
    }))

    const imgAttrs = computed(() => {
      const attrs: ImageAttrs = _base.imageAttrs.value

      if (props.sizes) {
        attrs.sizes = imgSizes.value.sizes
        attrs.srcset = imgSizes.value.srcset
      }

      return attrs
    })

    const imgPlaceholder = computed(() => {
      let placeholder = props.placeholder

      if (placeholder === '') {
        placeholder = true
      }

      if (!placeholder || placeholderLoaded.value) {
        return false
      }

      if (typeof placeholder === 'string') {
        return placeholder
      }

      const size = (Array.isArray(placeholder)
        ? placeholder
        : (typeof placeholder === 'number' ? [placeholder, placeholder] : [10, 10])) as [w: number, h: number, q: number]

      return $img(props.src!, {
        ..._base.imageEdits.value,
        resize: {
          ..._base.imageEdits.value.resize,
          width: size[0],
          height: size[1],
        },
      }, {
        ..._base.imageOptions.value,
        quality: size[2] || 50,
      })
    })

    const imgDpi = computed(() => {
      return $img.getDimensions(props.src!, {
        ..._base.imageOptions.value,
        dpi: props.dpi,
        edits: {
          ..._base.imageEdits.value,
          resize: {
            ..._base.imageEdits.value.resize,
            width: normalizeSize(props.width),
            height: normalizeSize(props.height),
          },
        },
      })
    })

    const imgMainSrc = computed(() =>
      props.sizes
        ? imgSizes.value.src
        : props.dpi
          ? imgDpi.value.src
          : $img(props.src!, _base.imageEdits.value, _base.imageOptions.value))

    const imgSrc = computed(() => imgPlaceholder.value ? imgPlaceholder.value : imgMainSrc.value)

    if (props.preload) {
      const isResponse = Object.values(imgSizes.value).every(v => v)
      useHead({
        link: [{
          rel: 'preload',
          as: 'image',
          ...(!isResponse
            ? { href: imgSrc.value }
            : {
                href: imgSizes.value.src,
                imagesizes: imgSizes.value.sizes,
                imagesrcset: imgSizes.value.srcset,
              }
          ),
        }],
      })
    }

    if (process.server && process.env.prerender) {
      const sources = [
        imgSrc.value,
        ...(imgSizes.value.srcset || '').split(',').map(s => s.split(' ')[0]),
      ].filter(s => s && s.includes('/api/images/'))
      appendHeader(useRequestEvent(), 'X-Nitro-Prerender', sources.join(','))
    }

    const imgEl = ref<HTMLImageElement | null>(null)

    onMounted(() => {
      if (imgPlaceholder.value) {
        const img = new Image()
        img.src = imgMainSrc.value
        img.onload = () => {
          if (imgEl.value) {
            imgEl.value.src = imgMainSrc.value
            placeholderLoaded.value = true
          }
        }
      }
    })

    return () => h('img', {
      ref: imgEl,
      key: imgSrc.value,
      src: imgSrc.value,
      ...imgAttrs.value,
      ...context.attrs,
    })
  },
})
