import type { ExtractPropTypes } from 'vue'
import type { ImageEdits, ImageFit, ImageFormat, ImageScreenSizes, ImageSrc } from '../../types'
import { getColor, normalizeSize } from '../utils'

export const baseImageProps = {
  // image src
  src: { type: [String, Object as () => ImageSrc], required: true },

  // image edits
  format: String as () => ImageFormat,
  quality: [String, Number],
  background: String,
  fit: String as () => ImageFit,
  edits: Object as () => ImageEdits,
  effort: [String, Number],

  // options
  preset: String,

  sizes: [String, Object as () => ImageScreenSizes],
  preload: { type: Boolean, default: undefined },
  dpi: [String, Array as () => Array<string | number>],

  // img el attributes
  width: [String, Number],
  height: [String, Number],
  alt: String,
  referrerpolicy: String,
  usemap: String,
  ariaDetails: String,
  ismap: { type: Boolean, default: undefined },
  loading: String,
  crossorigin: {
    type: [Boolean, String] as unknown as () => undefined | boolean | '' | 'anonymous' | 'use-credentials',
    default: undefined,
    validator: (v: any) => ['anonymous', 'use-credentials', '', true, false].includes(v),
  },
  decoding: {
    type: String as () => 'async' | 'auto' | 'sync',
    validator: (v: string) => ['async', 'auto', 'sync'].includes(v),
  },
}

export const useBaseImage = (props: ExtractPropTypes<typeof baseImageProps>) => {
  const imageAttrs = computed(() => ({
    'width': normalizeSize(props.width),
    'height': normalizeSize(props.width),
    'alt': props.alt,
    'referrerpolicy': props.referrerpolicy,
    'usemap': props.usemap,
    'aria-details': props.ariaDetails,
    'ismap': props.ismap,
    'crossorigin': (props.crossorigin === true ? 'anonymous' : props.crossorigin) || undefined,
    'loading': props.loading,
    'decoding': props.decoding,
  } as const))

  const imageOptions = computed(() => ({
    quality: props.quality,
    effort: props.effort,
    preset: props.preset,
  } as const))

  const imageEdits = computed(() => ({
    ...props.edits,
    resize: {
      ...(props.edits?.resize ?? {}),
      fit: props.fit,
      background: props.background ? getColor(props.background) : undefined,
      width: props.width,
      height: props.height,
    },
    toFormat: props.format,
  } as const))

  return { imageAttrs, imageOptions, imageEdits }
}
